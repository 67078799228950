import axios from 'axios'

const services = {
  prepareError (v) {
    if (!v.response) throw(v)
    const { status, data } = v.response
    if (status === 403) window.location.href = '/#/login'
    else if (status === 500 && data.error ) throw (data.error)
  },
  setToken (v) {
    //axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
    axios.defaults.headers.common['Authorization'] = `Bearer ${v}` 
    localStorage.setItem(`jwt`, v ? v : '')
    return
  },
  getToken () {
    const aux = localStorage.getItem(`jwt`)
    //axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
    axios.defaults.headers.common['Authorization'] = `Bearer ${aux}` 
    return aux && aux.length > 0 ? aux : null
  },
  getAPIURL (path) {
    let urlBase = process.env.VUE_APP_API_URL_MESSAGES
    let result = `${window.location.protocol}//${urlBase}/${path}`
    return result.concat('?')
  },
  getAll (url, startIndex = 0, itemPerPage = 100000, searchValue = '', last24h = false, status) {
    const urlFinal = this.getAPIURL(url).concat(`&start_index=${startIndex}&item_per_page=${itemPerPage}&search_value=${searchValue}`, last24h ? '&last_24_hours=true' : '', status ? `&status=${status.join(',')}` : '')
    return axios.get(urlFinal)
      .then(response => response.data)
      .catch((error) => {
        this.prepareError(error)
      })
      // .finally(() => { store.state.app.loading = false })
  },
  getItem (url) {
    alert(this.getAPIURL(url))
    return axios.get(this.getAPIURL(url))
      .then(response => response.data.shift())
      .catch((error) => {
        this.prepareError(error)
      }) 
      //.finally(() => {})   
  },
  addItem (url, body) {
    return axios.post(this.getAPIURL(url), body)
      .then(response => response)
      .catch((error) => {
        this.prepareError(error)
      })
      //.finally(() => { store.state.app.loading = false })
  },
  updateItem (url, body) {
    return axios.patch(this.getAPIURL(url), body)
      .then(response => response)
      .catch((error) => {
        this.prepareError(error)
      })
      //.finally(() => { store.state.app.loading = false })
  },
  deleteItem (url, body) {
    return axios.delete(this.getAPIURL(url), { data: body })
      .then(response => response)
      .catch((error) => {
        this.prepareError(error)
      })
      //.finally(() => { store.state.app.loading = false })
  },
  /*
  put (environment, url, body) {
    store.state.app.loading = true
    return axios.put(this.getAPIURL(environment, url), body)
      .then(response => response)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      })
      .finally(() => { store.state.app.loading = false })
  },
  */
}

export default services

