<template>
  <div>messages<br/>{{messages}}</div>
</template>
<script>
import apiMessages from '@/services/apiMessages'
export default {
  data: () => ({
    messages: null,
  }),
  mounted () {
    this.handleGetMessages()
  },
  methods: {
    handleGetMessages () {
      apiMessages.getAll (`v1/dtouch/messages/`)
        .then(response => {
          this.messages = response
        })
    },
  },
}
</script>

